<template>
  <ordinals-header/>
  <router-view/>
  <ordinals-footer/>
</template>

<script>
import ordinalsHeader from "@/components/ordinals-header";
import ordinalsFooter from "@/components/ordinals-footer";

export default {
  name: 'App',
  components: {
    ordinalsHeader,
    ordinalsFooter
  }
}
</script>

<style lang="sass">
  #app
    display: flex
    flex-direction: column
    align-items: center
    gap: 20px

    @media (min-width: $desktopScreenMinWidth)
      width: 634px
      padding-top: 70px
      padding-bottom: 150px

    @media (max-width: $mobileScreenMaxWidth)
      width: 100%
      padding: 20px 20px 40px
</style>