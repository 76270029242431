<template>
  <div class="ordinals-footer">
    <h3 class="l">Our contacts</h3>

    <div class="footer-social-icons">
      <div
          class="footer-social-icon-cont"
          v-for="social in socialList"
          :key="social.name"
      >
        <a
            class="footer-social-icon"
            :href="social.link"
            :title="social.title"
            target="_blank"
            rel="nofollow"
            :style="`background-image: url(/assets/images/social-icons/${ social.imagePath });`"
        >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { socialList } from "@/components/footer/social-icons";

export default {
  name: "ordinals-footer",
  data() {
    return {
      socialList
    }
  }
}
</script>

<style lang="sass">
  .ordinals-footer
    display: flex
    flex-direction: column
    align-items: center

    @media (min-width: $desktopScreenMinWidth)
      gap: 30px
      margin-top: 30px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 20px
      margin-top: 20px

    .footer-social-icons
      display: flex
      gap: 20px

      .footer-social-icon-cont
        width: 40px
        height: 40px

        .footer-social-icon
          display: block
          width: 100%
          height: 100%
          +background-image-settings()
          +border-radius(999px)
</style>