<template>
  <div class="ordinals-order-registration-how">
    <div class="how-window">
      <button class="how-window-close-button" @click="$emit('closeHowWindow')"></button>

      <h2 class="l">How does this work?</h2>

      <div class="how-info">
        <div class="how-instructions">
          <div
              class="how-instruction-item"
              v-for="(item, index) in howInstruction"
              :key="index"
          >
            <div class="instruction-item-number">
              <h1 class="s">{{ index + 1 }}</h1>
            </div>

            <div class="instruction-item-text">
              <h5 class="m">{{ item }}</h5>
            </div>
          </div>
        </div>

        <div class="how-text">
          <h2 class="s">Note: </h2>
          <h4 class="m">If you do not provide a BTC
            address, we will hold the inscription
            for you until you get one set up. When
            you have a wallet set up, simply visit
            the order check tool to add your address
            and receive your inscription.In case of issues
            or you need any help, please open a ticket
            in the Satoshibles discord and give us your
            order ID, we will be happy to help you.</h4>
        </div>
      </div>

      <router-link to="/faq" class="how-window-button" v-slot="{navigate}">
        <button @click="navigate" class="ordinals-button-type1">
          View FAQ
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { howInstruction } from "@/components/order/registration/instruction";

export default {
  name: "ordinals-order-registration-how",
  data() {
    return {
      howInstruction
    }
  }
}
</script>

<style lang="sass">
  .ordinals-order-registration-how
    position: fixed
    z-index: 10
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.95)
    display: flex
    justify-content: center
    align-items: center

    .how-window
      background-color: $networkFeeBackground
      border: 1px solid $gray
      display: flex
      flex-direction: column
      gap: 30px
      position: relative

      @media (min-width: $desktopScreenMinWidth)
        padding: 50px
        +border-radius(20px)
        width: 912px
        align-items: center

      @media (max-width: $mobileScreenMaxWidth)
        padding: 40px 20px
        width: 100vw

      .how-window-close-button
        position: absolute
        background-color: transparent
        background-image: url("/src/assets/images/svg/how-window-close-button.svg")
        +background-image-settings()
        width: 40px
        height: 40px

        @media (min-width: $desktopScreenMinWidth)
          top: 20px
          right: 20px

        @media (max-width: $mobileScreenMaxWidth)
          top: 10px
          right: 10px

      .how-info
        display: flex
        flex-direction: column

        @media (min-width: $desktopScreenMinWidth)
          gap: 40px

        @media (max-width: $mobileScreenMaxWidth)
          gap: 30px

        .how-instructions
          display: flex
          flex-direction: column
          gap: 12px

          .how-instruction-item
            display: flex

            @media (min-width: $desktopScreenMinWidth)
              gap: 10px
              align-items: center

            @media (max-width: $mobileScreenMaxWidth)
              gap: 12px

            .instruction-item-number
              background: linear-gradient(135deg, #1CC287 0%, #088658 100%)
              +border-radius(999px)
              display: flex
              justify-content: center
              align-items: center

              @media (min-width: $desktopScreenMinWidth)
                width: 40px
                height: 40px

              @media (max-width: $mobileScreenMaxWidth)
                width: 24px
                height: 24px

              > *
                line-height: 1
                color: $darkText

                @media (min-width: $desktopScreenMinWidth)
                  padding-bottom: 6px

                @media (max-width: $mobileScreenMaxWidth)
                  padding-bottom: 2px

            .instruction-item-text
              flex: 1
              text-align: left

        .how-text
          text-align: justify

          > *
            display: inline
</style>