<template>
  <div class="ordinals-order-payment">
    <div class="payment-main">
      <div class="payment-main-info">
        <h1 class="m">Complete your transaction</h1>

        <h4 class="l">Send your payment to complete the process. You must send your payment in a single transaction.</h4>
      </div>

      <div class="payment-details">
        <h2 class="l">Copy these details to pay with any Bitcoin wallet</h2>

        <div class="payment-details-info">
          <div class="payment-sum">
            <h5 class="l">Send this amount</h5>

            <div class="payment-details-input">
              <input
                  type="text"
                  class="payment-details-input ordinals-input-text"
                  readonly
                  :value="this.orderCost === 0 ? 'no data' : this.orderCost + ' BTC'">
              <button class="payment-details-copy" @click="copyTextToClipboard"></button>
            </div>
          </div>

          <div class="payment-address">
            <h5 class="l">To this address</h5>

            <div class="payment-details-input">
              <input
                  type="text"
                  class="payment-details-input ordinals-input-text"
                  readonly
                  value="3Fqbn8SQLSrLKdvhgGSVS9ydYy1iavUj4U">
              <button class="payment-details-copy" @click="copyTextToClipboard"></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="payment-finish">
      <div class="payment-finish-title">
        <h2 class="l">Or scan this QR code to pay <br> with a supporting Bitcoin wallet</h2>
      </div>

      <div class="payment-finish-info">
        <div class="payment-qrcode"></div>

        <div class="payment-finish-info-text">
          <h6 class="l">Depending on fee amount
            and network demand, your inscription
            may take hours or days to confirm. Gamma
            cannot refund or increase fees once broadcast.</h6>
        </div>
      </div>

      <router-link to="/complete" class="ordinals-button-cont" v-slot="{navigate}">
        <button @click="navigate" class="ordinals-button-type1">
          Continue
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {scrollToBar} from "@/components/mixins/scrollToBar";

export default {
  name: "ordinals-order-payment",
  props: {
    orderCost: Number,
    readyToOrder: Boolean,
  },
  methods: {
    fallbackCopyTextToClipboard(text) {
      let textArea = document.createElement("textarea")
      textArea.value = text

      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        document.execCommand('copy')
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },

    copyTextToClipboard(e) {
      const text = this.removeBTCFromString(e.target.previousElementSibling.value)

      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
      } else {
        navigator.clipboard.writeText(text).catch(e => console.log(e))
      }
    },

    removeBTCFromString(text) {
      return text.split(" ")[0]
    }
  },
  mounted() {
    if (this.readyToOrder !== true) {
      router.replace("/")
    } else {
      window.onbeforeunload = () => true
      scrollToBar("bar")
      this.$emit("updateOrderStatusBar", 50)
    }
  }
}
</script>

<style lang="sass">
  .ordinals-order-payment
    display: flex
    flex-direction: column
    width: 100%

    @media (min-width: $desktopScreenMinWidth)
      gap: 50px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 30px

    ~ .order-status-cont
      .order-status-title
        > *:nth-child(2)
          color: $green

      .status-progress
        width: 50% !important

    .payment-main
      display: flex
      flex-direction: column
      gap: 40px

      .payment-main-info
        display: flex
        flex-direction: column
        gap: 20px

      .payment-details
        display: flex
        flex-direction: column

        @media (min-width: $desktopScreenMinWidth)
          gap: 40px

        @media (max-width: $mobileScreenMaxWidth)
          gap: 20px

        .payment-details-info
          display: flex
          flex-direction: column
          gap: 20px

          > div
            display: flex
            flex-direction: column
            gap: 12px

            .payment-details-input
              width: 100%
              background-color: $networkFeeBackground
              border: 1px solid $gray
              +border-radius(10px)
              display: flex
              padding: 7px 15px 8px
              gap: 35px

              input
                border: none
                padding: 7px 15px 8px
                font-family: Raleway, sans-serif
                flex: 1

              .payment-details-copy
                background-color: unset
                background-image: url("/src/assets/images/svg/copyButton.svg")
                +background-image-settings(unset)
                width: 20px
                height: 20px
                padding: 7px 15px 8px
                box-sizing: content-box
                +border-radius(5px)

                &:hover
                  background-color: $statusBarBackground

                &:active
                  background-color: rgba(240, 251, 247, 0.07)

    .payment-finish
      display: flex
      flex-direction: column
      gap: 30px
      align-items: center

      .payment-finish-title
        @media (min-width: $desktopScreenMinWidth)

        @media (max-width: $mobileScreenMaxWidth)
          display: none

      .payment-finish-info
        display: flex
        gap: 20px
        justify-content: center

        .payment-qrcode
          @media (min-width: $desktopScreenMinWidth)
            background-image: url("/src/assets/images/svg/qrcode.svg")
            width: 140px
            height: 140px

          @media (max-width: $mobileScreenMaxWidth)
            display: none

        .payment-finish-info-text
          background-color: $statusBarBackground
          border: 1px solid $gray

          @media (min-width: $desktopScreenMinWidth)
            padding: 33px 10px 35px
            +border-radius(14px)
            flex: 1

          @media (max-width: $mobileScreenMaxWidth)
            padding: 21px 20px 23px
            min-width: 100vw
</style>