<template>
  <div class="ordinals-check">
    <h2 class="l">Order Status Check:</h2>

    <div class="check-main">
      <div class="check-main-usability">
        <input type="text" class="ordinals-input-text black" placeholder="Enter order ID" id="input-text-check-order">

        <button class="ordinals-button-type1" @click="getOrderInfo">Get order info</button>
      </div>

      <div class="check-results" v-if="searchResult !== null">
        <div class="check-results-window" v-if="!searchError">
          <h2 class="l">Order Info</h2>

          <div class="check-results-window-main">
            <div class="check-window-item">
              <h6 class="s">Order ID:</h6>

              <h3 class="m">{{ this.searchResult.order_id }}</h3>
            </div>

            <div class="check-window-item">
              <h6 class="s">Status:</h6>

              <h3 class="m">{{ this.searchResult.status }}</h3>
            </div>

            <div class="check-window-item">
              <h6 class="s">Cost:</h6>

              <h3 class="m">{{ this.searchResult.cost }} BTC</h3>
            </div>

            <div class="check-window-item">
              <h6 class="s">Address where to get NFT:</h6>

              <h3 class="m">{{ this.searchResult.nft_address !== null ? this.searchResult.nft_address : "-" }}</h3>
            </div>

            <div class="check-window-item time">
              <div class="check-window-item-subitem">
                <h6 class="s">Date:</h6>

                <h3 class="m">{{ getDayMonthYearFromString(this.searchResult.created) }}</h3>
              </div>

              <div class="check-window-item-subitem">
                <h6 class="s">Time:</h6>

                <h3 class="m">{{ getHoursMinutesFromString(this.searchResult.created) }}</h3>
              </div>
            </div>
          </div>
        </div>

        <h2 class="l red" v-else>Invalid Order Id</h2>
      </div>

      <div class="check-main-attention">
        <h2 class="m">Attention: </h2>

        <h4 class="l">Never share your order number
          with anyone except a team member
          in the Satoshibles discord via a support ticket</h4>
      </div>

      <ordinals-check-past-orders
          v-if="this.ordersList.length > 0"
          :ordersList="ordersList"
          @updateOrdersList="updateOrdersList"
      />
    </div>
  </div>
</template>

<script>
import ordinalsCheckPastOrders from "@/components/check/ordinals-check-past-orders";
import {scrollToTop} from "@/components/mixins/scrollToTop";
import {getDayMonthYearFromString} from "@/components/mixins/getDayMonthYearFromString";
import {getHoursMinutesFromString} from "@/components/mixins/getHoursMinutesFromString";

export default {
  name: "ordinals-check",
  components: {
    ordinalsCheckPastOrders
  },
  data() {
    return {
      getDayMonthYearFromString,
      getHoursMinutesFromString,

      searchResult: null,
      searchError: false,

      dataBase: null,
      orderId: null,
      ordersList: [],
    }
  },
  async created() {
    this.dataBase = await this.getDataBase()
    this.ordersList = await this.getOrderListFromIndexedDB()
  },
  methods: {
    async updateOrdersList() {
      this.dataBase = await this.getDataBase()
      this.ordersList = await this.getOrderListFromIndexedDB()
    },

    async getOrderInfo() {
      this.getOrderId()

      try {
        const url = "http://5.63.152.219:8000/api/nft/v1/orders/?order_id=" + this.orderId
        const config = {
          headers: {
            "Content-Type": "application/json"
          }
        }

        await this.axios.get(
            url,
            config
        ).then((response) => {
          this.searchResult = response.data
          this.searchError = false
        })
      }
      catch(e) {
        console.log(e.target)
        this.searchResult = false
        this.searchError = true
      }
    },

    getOrderId() {
      const input = document.getElementById("input-text-check-order")
      this.orderId = input.value
    },

    async getDataBase() {
      return new Promise((resolve, reject) => {
        let openRequest = indexedDB.open("ordersList", 1)

        openRequest.onerror = e => {
          console.log("Error opening indexedDataBase", e)
          reject('Error')
        }

        openRequest.onsuccess = e => {
          resolve(e.target.result)
        }

        openRequest.onupgradeneeded = e => {
          let db = e.target.result
          if (!db.objectStoreNames.contains("ordersList")) {
            db.createObjectStore("ordersList", { keyPath: 'id' })
          }
        }
      })
    },

    async getOrderListFromIndexedDB() {
      return new Promise((resolve) => {
        let transaction = this.dataBase.transaction("ordersList", "readonly")

        transaction.oncomplete = () => {
          resolve(ordersList.sort((a,b) => {
            return new Date(b.date) - new Date(a.date)
          }))
        }

        let orderList = transaction.objectStore("ordersList")

        let ordersList = []

        orderList.openCursor().onsuccess = e => {
          let cursor = e.target.result
          if (cursor) {
            ordersList.push(cursor.value)
            cursor.continue()
          }
        }
      })
    }
  },
  mounted() {
    scrollToTop()
  }
}
</script>

<style lang="sass">
  .ordinals-check
    display: flex
    flex-direction: column
    align-items: center
    width: 100%

    @media (min-width: $desktopScreenMinWidth)
      gap: 60px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 30px

    .check-main
      display: flex
      flex-direction: column
      width: 100%

      @media (min-width: $desktopScreenMinWidth)
        gap: 50px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 40px

      .check-main-usability
        display: flex
        flex-direction: column

        @media (min-width: $desktopScreenMinWidth)
          gap: 30px
          align-items: center

        @media (max-width: $mobileScreenMaxWidth)
          gap: 20px

        input
          padding: 12px 14px 14px
          +border-radius(4px)
          width: 100%

        .ordinals-button
          padding-left: 40px
          padding-right: 40px
          background-color: $green
          color: $darkText
          font-family: Raleway, sans-serif
          font-weight: 800
          font-size: 18px
          line-height: 21px

      .check-main-attention
        text-align: center
        > *
          display: inline

      .check-results
        display: flex
        justify-content: center

        .check-results-window
          display: flex
          flex-direction: column
          align-items: center
          background-color: $networkFeeBackground
          border: 1px solid $gray
          gap: 20px

          @media (min-width: $desktopScreenMinWidth)
            +border-radius(20px)
            width: 100%
            padding: 30px 40px

          @media (max-width: $mobileScreenMaxWidth)
            min-width: 100vw
            padding: 30px 20px

          .check-results-window-main
            display: flex
            flex-direction: column
            gap: 16px

            @media (min-width: $desktopScreenMinWidth)

            @media (max-width: $mobileScreenMaxWidth)
              width: 100%
              align-items: center

            .check-window-item
              display: flex
              align-items: baseline
              text-align: center

              @media (min-width: $desktopScreenMinWidth)
                gap: 10px

              @media (max-width: $mobileScreenMaxWidth)
                gap: 4px
                flex-direction: column
                align-items: center

              > *:last-child
                word-break: break-all

              &.time
                @media (min-width: $desktopScreenMinWidth)
                  gap: 16px

                @media (max-width: $mobileScreenMaxWidth)
                  gap: 20px
                  align-items: center

              .check-window-item-subitem
                display: flex

                @media (min-width: $desktopScreenMinWidth)
                  gap: 10px

                @media (max-width: $mobileScreenMaxWidth)
                  gap: 4px
                  flex-direction: column
                  align-items: center
</style>