export function clearIndexedDB(indexedDBName) {
    let openRequest = indexedDB.open(indexedDBName, 1)

    openRequest.onupgradeneeded = function() {
        let db = openRequest.result
        if (!db.objectStoreNames.contains(indexedDBName)) {
            db.createObjectStore(indexedDBName, { keyPath: 'id' })
        }
    };

    openRequest.onerror = function() {
        console.error("Open IndexedDB error: ", openRequest.error)
    };

    openRequest.onsuccess = function() {
        let db = openRequest.result
        let transaction = db.transaction(indexedDBName, "readwrite")
        let dataBase = transaction.objectStore(indexedDBName)

        let request = dataBase.clear()

        request.onerror = function() {
            console.log("Clear indexedDB error: ", request.error)
        };
    };
}