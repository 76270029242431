<template>
  <div class="ordinals-faq">
    <h1 class="m">FAQ</h1>

    <div class="faq-main">
      <div class="faq-main-quote">
        <h5 class="l">This FAQ is updated over time, check back for more info 👍</h5>
      </div>

      <div class="faq-main-info">
        <template v-for="(part, index) in faqTexts" :key="index">
          <div class="faq-main-info-part">
            <h2 class="l">{{ part.title }}</h2>

            <div class="faq-main-info-part-questions">
              <div class="faq-question" v-for="(question, index) in part.children" :key="index">
                <h3 class="l">{{ question.title }}</h3>

                <div class="faq-question-text">
                  <h6 class="l" v-for="(text, index) in question.text" :key="index">
                    <template v-if="text.type === 'text'">
                      {{ text.content }}
                    </template>

                    <template v-else-if="text.type === 'link'">
                      <a :href="text.link" class="ordinals-a-faq">{{ text.content }}</a>
                    </template>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <hr>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {scrollToTop} from "@/components/mixins/scrollToTop";
import {faqTexts} from "@/components/faq/texts";

export default {
  name: "ordinals-faq",
  data() {
    return {
      faqTexts
    }
  },
  mounted() {
    scrollToTop()
  }
}
</script>

<style lang="sass">
  .ordinals-faq
    display: flex
    flex-direction: column
    gap: 30px
    align-items: center
    width: 100%

    .faq-main
      display: flex
      flex-direction: column
      gap: 50px
      width: 100%

      .faq-main-quote
        background-color: $statusBarBackground
        +border-radius(14px)
        border: 1px solid $gray
        text-align: center

        @media (min-width: $desktopScreenMinWidth)
          padding: 23px 42px

        @media (max-width: $mobileScreenMaxWidth)
          padding: 12px 10px

      .faq-main-info
        display: flex
        flex-direction: column

        @media (min-width: $desktopScreenMinWidth)
          gap: 40px

        @media (max-width: $mobileScreenMaxWidth)
          gap: 30px

        hr
          background-color: $gray
          width: 100%
          height: 1px

          &:last-of-type
            display: none

        .faq-main-info-part
          display: flex
          flex-direction: column
          gap: 40px

          @media (min-width: $desktopScreenMinWidth)

          @media (max-width: $mobileScreenMaxWidth)
            align-items: center

          .faq-main-info-part-questions
            display: flex
            flex-direction: column

            @media (min-width: $desktopScreenMinWidth)
              gap: 40px

            @media (max-width: $mobileScreenMaxWidth)
              gap: 30px

            .faq-question
              display: flex
              flex-direction: column
              gap: 16px

              @media (min-width: $desktopScreenMinWidth)

              @media (max-width: $mobileScreenMaxWidth)
                > *:first-child
                  text-align: center

              .faq-question-text
                > *
                  display: inline
</style>