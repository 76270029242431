<template>
  <div class="ordinals-order">
    <router-view
        @updateOrderStatusBar="updateOrderStatusBar"
        @updateOrderImage="updateOrderImage"
        @updateOrderCost="updateOrderCost"
        @updateOrderBTCAddress="updateOrderBTCAddress"
        @updateReadyToOrder="updateReadyToOrder"
        @clearAllData="clearAllData"
        :orderImageFormData="orderImageFormData"
        :orderBTCAddress="orderBTCAddress"
        :orderCost="orderCost"
        :readyToOrder="readyToOrder"
    />

    <div class="order-status-cont">
      <div class="order-status-title">
        <h6 class="m">Request</h6>
        <h6 class="m">Payment</h6>
        <h6 class="m">Complete</h6>
      </div>

      <div class="order-status-bar">
        <div class="status-progress" :style="`width: ${orderStatusBar}%;`"></div>
      </div>
    </div>

    <div class="order-slogan" id="bar">
      <h6 class="m">We make it easy to inscribe ordinals.</h6>&nbsp;
      <h6 class="m">Like, really easy.</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "ordinals-order",
  data() {
    return {
      orderImageFormData: null,
      orderBTCAddress: null,
      orderCost: 0,

      readyToOrder: false,

      orderStatusBar: 0
    }
  },
  methods: {
    updateOrderStatusBar(percent) {
      this.orderStatusBar = percent
    },

    updateOrderImage(orderImageFormData) {
      this.orderImageFormData = orderImageFormData
    },

    updateOrderCost(orderCost) {
      this.orderCost = orderCost
    },

    updateOrderBTCAddress(orderBTCAddress) {
      this.orderBTCAddress = orderBTCAddress
    },

    updateReadyToOrder(readyToOrder) {
      this.readyToOrder = readyToOrder
    },

    clearAllData() {
      this.orderImageFormData = null
      this.orderBTCAddress = null
      this.orderCost = 0
      this.readyToOrder = false
      this.orderStatusBar = 0
    }
  }
}
</script>

<style lang="sass">
  .ordinals-order
    display: flex
    flex-direction: column-reverse
    align-items: center
    text-align: center
    width: 100%
    gap: 20px

    .order-slogan
      display: flex
      justify-content: center
      flex-wrap: wrap

    .order-status-cont
      width: 100vw
      display: flex
      position: sticky
      background-color: $mainBackground
      padding: 10px 25px
      top: 0
      flex-direction: column
      gap: 10px
      z-index: 2

      @media (min-width: $desktopScreenMinWidth)
        padding: 10px calc((100vw - 100%) / 2)

      @media (max-width: $mobileScreenMaxWidth)

      .order-status-title
        display: flex
        justify-content: space-between
        color: $gray
        +user-select(none)

        > *
          transition: 1s

        > *:first-child
          color: $green

      .order-status-bar
        height: 10px
        background-color: $statusBarBackground
        +border-radius(10px)
        display: flex

        .status-progress
          background-color: $green
          height: 100%
          width: 0
          +border-radius(10px)
          +transition(width .6s linear)

          @media (min-width: $desktopScreenMinWidth)
            min-width: 52px

          @media (max-width: $mobileScreenMaxWidth)
            min-width: 40px
</style>