<template>
  <div class="ordinals-order-registration">
    <div class="main-upload">
      <h3 class="l">Upload your files to begin, we will inscribe and send them to you:</h3>

      <ordinals-order-registration-upload-file
          @updateCurrentFileSize="updateCurrentFileSize"
          @updateReadyImageToOrder="updateReadyImageToOrder"
          @setFinallyFormData="setFormData"
      />
    </div>

    <div class="main-btc-address">
      <h3 class="l">Write the bitcoin address to receive your ordinal:</h3>

      <input
          class="ordinals-input-text black"
          type="text"
          name=""
          id=""
          placeholder="Receive BTC Address"
          @input="setBTCAddress"
          @change="checkBTCAddress"
      >
    </div>

    <div class="main-total">
      <ordinals-order-registration-select-network-fee @updateSelectedNetworkFeeByByte="updateSelectedNetworkFeeByByte"/>

      <h6 class="m gray">For a faster transaction, set a higher value than recommended.</h6>

      <div class="main-total-sum-cont">
        <div class="main-total-sum">
          <div class="main-total-sum-parts">
            <div class="total-sum-part">
              <h6 class="m">Network Fee:</h6>

              <div class="total-sum-info">
                <h6 class="m">{{ this.networkFeeSatoshi }} sats</h6>

                <h6 class="m">~${{ this.networkFeeDollar }}</h6>
              </div>
            </div>

            <div class="total-sum-part">
              <h6 class="m">Service Fee:</h6>

              <div class="total-sum-info">
                <h6 class="m">{{ this.serviceFeeSatoshi }} sats</h6>

                <h6 class="m">~${{ this.serviceFeeDollar }}</h6>
              </div>
            </div>
          </div>

          <div class="main-total-finish">
            <h5 class="l">Total:</h5>

            <div class="total-finish-info">
              <h5 class="l">{{ this.networkFeeSatoshi + this.serviceFeeSatoshi }} sats</h5>

              <h5 class="l">~${{ Math.round((this.networkFeeDollar + this.serviceFeeDollar) * 100) / 100 }}</h5>
            </div>
          </div>
        </div>

        <div class="main-total-buttons">
          <div class="main-buttons-info">
            <ordinals-order-registration-how v-show="activeHowWindow" @closeHowWindow="activeHowWindow = false"/>

            <button class="ordinals-button-type1" @click="activeHowWindow = true">How does it work?</button>

            <router-link to="/payment" v-slot="{navigate}" class="ordinals-button-cont">
              <button
                  @click="navigate"
                  class="ordinals-button-type1"
                  :disabled="!(readyImageToOrder && bitcoinCost !== 0 && readyBTCAddressToOrder)"
              >
                Submit & Pay invoice
              </button>
            </router-link>
          </div>

          <router-link to="/check" v-slot="{navigate}" class="ordinals-button-cont">
            <button @click="navigate" class="ordinals-button-type2">
              Check orders
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ordinalsOrderRegistrationHow from "@/components/order/registration/ordinals-order-registration-how"
import ordinalsOrderRegistrationUploadFile
  from "@/components/order/registration/ordinals-order-registration-upload-file";
import ordinalsOrderRegistrationSelectNetworkFee
  from "@/components/order/registration/ordinals-order-registration-select-network-fee";
import {convertSatoshiToDollar} from "@/components/mixins/convertSatoshiToDollar";

export default {
  name: "ordinals-order-registration",
  components: {
    ordinalsOrderRegistrationHow,
    ordinalsOrderRegistrationUploadFile,
    ordinalsOrderRegistrationSelectNetworkFee
  },
  props: {
    orderInfo: Object
  },
  data() {
    return {
      currentFileSize: 0,

      readyImageToOrder: false,

      formData: null,

      BTCAddress: '',
      readyBTCAddressToOrder: false,

      selectedNetworkFeeByByte: 10,

      bitcoinCost: 0,

      networkFeeSatoshi: 0,
      networkFeeDollar: 0,

      serviceFeeSatoshi: 24000,
      serviceFeeDollar: 0,

      activeHowWindow: false
    }
  },
  methods: {
    checkBTCAddress(event) {
      if (!this.readyBTCAddressToOrder && this.BTCAddress !== "") {
        event.target.classList.add("ordinals-wrong")
      }
    },

    updateCurrentFileSize(fileSize) {
      this.currentFileSize = fileSize
    },

    updateReadyImageToOrder(readyImageToOrder) {
      this.readyImageToOrder = readyImageToOrder
    },

    setFormData(formData) {
      this.formData = formData
    },

    checkBTC(address) {
      if (address.length === 62) {
        if (address.substr(0, 4) === "bc1p") {
          return true
        }
      }
      return false
    },

    setBTCAddress(event) {
      event.target.classList.remove("ordinals-wrong")
      const address = event.target.value
      this.BTCAddress = address

      this.readyBTCAddressToOrder = this.checkBTC(address)
    },

    // При изменении network fee сохраняем её
    updateSelectedNetworkFeeByByte(NetworkFeeByByte) {
      this.selectedNetworkFeeByByte = NetworkFeeByByte
    },

    setNetworkFeeDollar() {
      const cost = convertSatoshiToDollar(this.networkFeeSatoshi, this.bitcoinCost)
      this.networkFeeDollar = Math.round(cost * 100) / 100
    },

    setServiceFeeDollar() {
      const cost = convertSatoshiToDollar(this.serviceFeeSatoshi, this.bitcoinCost)
      this.serviceFeeDollar = Math.round(cost * 100) / 100
    },

    updateReadyToOrder() {
      this.$emit("updateReadyToOrder", this.readyImageToOrder && this.bitcoinCost !== 0 && this.readyBTCAddressToOrder)
    },

    async getBitcoinPrice() {
      try {
        const url = "http://5.63.152.219:8000/api/nft/v1/pages/first/data"
        const config = {
          headers: {
            "Content-Type": "application/json",
          }
        }

        await this.axios.get(
            url,
            config
        ).then(response => {
          this.bitcoinCost = JSON.parse(response.request.response).price
        });
      }
      catch(e) {
        console.log("Getting bitcoin price error", e)
        return null
      }
    }
  },
  mounted() {
    this.getBitcoinPrice()
    this.$emit("updateOrderStatusBar", 0)
    window.onbeforeunload = null
  },
  watch: {
    // Обновление цены BTC
    bitcoinCost: async function() {
      this.setNetworkFeeDollar()
      this.setServiceFeeDollar()
      this.updateReadyToOrder()
    },

    // При изменении веса файла изменяем окончательную сумму за файл
    currentFileSize: function() {
      this.networkFeeSatoshi = this.currentFileSize * this.selectedNetworkFeeByByte
    },

    // При изменении network fee изменяем окончательную сумму за файл
    selectedNetworkFeeByByte: function() {
      this.networkFeeSatoshi = this.currentFileSize * this.selectedNetworkFeeByByte
    },

    BTCAddress: function() {
      this.$emit("updateOrderBTCAddress", this.BTCAddress)
      this.updateReadyToOrder()
    },

    // При изменении окончательной стоимости за network fee обновляем её долларовый эквивалент
    networkFeeSatoshi: function() {
      this.setNetworkFeeDollar()

      const cost = (this.networkFeeSatoshi + this.serviceFeeSatoshi) / 100000000
      this.$emit("updateOrderCost", cost)
    },

    formData: function() {
      this.$emit("updateOrderImage", this.formData)
    },

    readyImageToOrder: function() {
      this.updateReadyToOrder()
    },
  }
}
</script>

<style lang="sass">
  .ordinals-order-registration
    display: flex
    flex-direction: column
    width: 100%

    @media (min-width: $desktopScreenMinWidth)
      gap: 50px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 40px

    ~ .order-status-cont .status-progress
      width: 50%

    .main-upload
      display: flex
      flex-direction:  column
      width: 100%

      @media (min-width: $desktopScreenMinWidth)
        gap: 30px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 20px

    .main-btc-address
      display: flex
      flex-direction: column

      @media (min-width: $desktopScreenMinWidth)
        gap: 30px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 20px

      input
        padding: 12px 14px 14px
        background-color: $white
        +border-radius(4px)

        &::placeholder
          color: $gray

    .main-total
      display: flex
      flex-direction: column

      @media (min-width: $desktopScreenMinWidth)
        gap: 50px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 20px

      .main-total-sum-cont
        display: flex
        flex-direction: column

        @media (min-width: $desktopScreenMinWidth)
          gap: 30px

        @media (max-width: $mobileScreenMaxWidth)
          gap: 20px

        .main-total-sum
          display: flex
          flex-direction: column
          background-color: $networkFeeBackground
          +border-radius(14px)
          border: 1px solid $gray

          @media (min-width: $desktopScreenMinWidth)
            gap: 20px
            padding: 30px 40px

          @media (max-width: $mobileScreenMaxWidth)
            gap: 10px
            padding: 20px

          .main-total-sum-parts
            display: flex
            flex-direction: column
            border-bottom: 1px solid $gray

            @media (min-width: $desktopScreenMinWidth)
              gap: 10px
              padding-bottom: 20px

            @media (max-width: $mobileScreenMaxWidth)
              gap: 20px
              padding-bottom: 10px

            .total-sum-part
              display: flex

              @media (min-width: $desktopScreenMinWidth)
                > *:first-child
                  width: 250px
                  text-align: left

              @media (max-width: $mobileScreenMaxWidth)
                flex-direction: column
                gap: 4px
                align-items: center

              .total-sum-info
                display: flex

                @media (min-width: $desktopScreenMinWidth)
                  justify-content: space-between
                  flex: 1

                @media (max-width: $mobileScreenMaxWidth)
                  gap: 20px

          .main-total-finish
            display: flex

            @media (min-width: $desktopScreenMinWidth)
              > *:first-child
                width: 250px
                text-align: left

            @media (max-width: $mobileScreenMaxWidth)
              flex-direction: column
              gap: 4px
              align-items: center

            .total-finish-info
              display: flex

              @media (min-width: $desktopScreenMinWidth)
                flex: 1
                justify-content: space-between

              @media (max-width: $mobileScreenMaxWidth)
                gap: 20px

        .main-total-buttons
          display: flex
          gap: 40px
          flex-direction: column
          align-items: center
          width: 100%

          .main-buttons-info
            display: flex
            gap: 20px
            width: 100%

            @media (min-width: $desktopScreenMinWidth)
              justify-content: center

            @media (max-width: $mobileScreenMaxWidth)
              flex-direction: column
</style>