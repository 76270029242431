<template>
  <h1 class="m red" v-if="creatingOrderError">Creating order error. Please, try again</h1>

  <h1 class="m orange" v-else-if="orderId === ''">Creating order, please wait...</h1>

  <div class="ordinals-order-complete" v-else>
    <h1 class="m">Thanks for the order!</h1>

    <div class="order-complete-main">
      <h2 class="l">Payment info</h2>

      <div class="complete-payment-info">
        <div class="complete-payment-item">
          <h6 class="s">Order ID:</h6>

          <h3 class="m">{{ this.orderId }}</h3>
        </div>

        <div class="complete-payment-item">
          <h6 class="s">Cost:</h6>

          <h3 class="m">{{ this.orderCost }} BTC</h3>
        </div>

        <div class="complete-payment-item">
          <h6 class="s">Recipient address:</h6>

          <h3 class="m">{{ this.recipientAddress }}</h3>
        </div>

        <div class="complete-payment-item">
          <h6 class="s">Address where to get NFT:</h6>

          <h3 class="m">-</h3>
        </div>

        <div class="complete-payment-item-oneLine">
          <div class="complete-payment-item">
            <h6 class="s">Date:</h6>

            <h3 class="m">{{ this.date }}</h3>
          </div>

          <div class="complete-payment-item">
            <h6 class="s">Time:</h6>

            <h3 class="m">{{ this.time }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import {scrollToBar} from "@/components/mixins/scrollToBar";
import {addObjectToIndexedDB} from "@/components/mixins/addObjectToIndexedDB";
import {getDayMonthYearFromDate} from "@/components/mixins/getDayMonthYearFromDate";
import {getHoursMinutesFromDate} from "@/components/mixins/getHoursMinutesFromDate";

export default {
  name: "ordinals-order-complete",
  props: {
    orderCost: Number,
    orderImageFormData: Object,
    readyToOrder: Boolean,
    orderBTCAddress: String,
  },
  data() {
    return {
      orderId: "",
      recipientAddress: "3Fqbn8SQLSrLKdvhgGSVS9ydYy1iavUj4U",
      date: null,
      time: null,

      creatingOrderError: false,
    }
  },
  methods: {
    async createOrder() {
      try {
        const url = "http://5.63.152.219:8000/api/nft/v1/orders/create?cost=" + Number(this.orderCost) + "&recipient_address=" + this.orderBTCAddress
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }

        await this.axios.post(
            url,
            this.orderImageFormData,
            config
        ).then(response => {
          const answerObject = JSON.parse(response.request.response)
          this.orderId = answerObject["order_id"]

          const date = new Date()

          this.date = getDayMonthYearFromDate(date)
          this.time = getHoursMinutesFromDate(date)
        });
      }
      catch(e) {
        console.log(e)
        this.creatingOrderError = true
      }

      if (this.orderId !== "") {
        addObjectToIndexedDB("ordersList", {
          id: this.orderId,
          date: new Date()
        })
      }

      window.onbeforeunload = null
    }
  },
  beforeUnmount() {
    this.$emit("clearAllData")
  },
  mounted() {
    if (this.readyToOrder !== true) {
      router.replace("/")
    } else {
      scrollToBar("bar")
      this.$emit("updateOrderStatusBar", 100)
      this.createOrder()
    }
  }
}
</script>

<style lang="sass">
  .ordinals-order-complete
    display: flex
    flex-direction: column
    gap: 30px

    ~ .order-status-cont
      .order-status-title
        > *
          color: $green

      .status-progress
        width: 100% !important

    .order-complete-main
      display: flex
      flex-direction: column
      gap: 20px
      background-color: $networkFeeBackground

      @media (min-width: $desktopScreenMinWidth)
        padding: 30px 40px
        +border-radius(20px)
        min-width: 808px
        border: 1px solid $gray

      @media (max-width: $mobileScreenMaxWidth)
        padding: 30px 20px
        width: 100vw
        border-top: 1px solid $gray
        border-bottom: 1px solid $gray

      .complete-payment-info
        display: flex
        flex-direction: column
        gap: 16px

        .complete-payment-item
          display: flex
          white-space: nowrap

          @media (min-width: $desktopScreenMinWidth)
            gap: 10px
            align-items: center

          @media (max-width: $mobileScreenMaxWidth)
            flex-direction: column
            align-items: center
            gap: 4px

            > *:last-child
              white-space: normal
              word-break: break-all

        .complete-payment-item-oneLine
          display: flex
          white-space: nowrap
          align-items: center

          @media (min-width: $desktopScreenMinWidth)
            gap: 20px

          @media (max-width: $mobileScreenMaxWidth)
            flex-direction: column
            gap: 16px
</style>