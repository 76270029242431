<template>
  <div class="ordinals-order-registration-select-network-fee">
    <h3 class="l">Select the Bitcoin network fee:</h3>

    <div class="network-fee-selection">
      <input
          type="radio"
          name="input-radio-fee-select"
          id="input-radio-fee-select-recommended"
          checked
          @change="selectRecommendedFee"
      >

      <label class="network-fee-select" for="input-radio-fee-select-recommended">
        <h3 class="m">Recommended</h3>

        <div class="select-option">
          <h5 class="l">10 sats/vB</h5>

          <h5 class="l">~1 hour</h5>
        </div>
      </label>

      <input
          type="radio"
          name="input-radio-fee-select"
          id="input-radio-fee-select-custom"
          @change="selectCustomFee"
      >

      <label class="network-fee-select" for="input-radio-fee-select-custom">
        <h3 class="m">Custom</h3>

        <div class="select-option">
          <select
              name=""
              id="select-fee-select-custom"
              class="network-fee-custom-select ordinals-select"
              @click="selectCustomFeeWithSelectClick"
              @change="selectCustomFeeWithSelectClick"
          >
            <option
                v-for="num in 94"
                :key="num"
                :selected="num === 15 - 4"
            >{{ num + 4 }}</option>
          </select>

          <h5 class="l">sats/vB</h5>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "ordinals-order-registration-select-network-fee",
  data() {
    return {
      selectedNetworkFeeByByte: 10
    }
  },
  methods: {
    // Клик по рекомендуемой комиссии
    selectRecommendedFee() {
      this.selectedNetworkFeeByByte = 10
    },

    // Клик по кастомной комиссии
    selectCustomFee() {
      const selectedFee = Number(document.getElementById("select-fee-select-custom").value)
      if (Number.isInteger(selectedFee) && selectedFee >= 1 && selectedFee <= 98) {
        this.selectedNetworkFeeByByte = selectedFee
      } else {
        const recommendedSelectionInput = document.getElementById("input-radio-fee-select-recommended")
        recommendedSelectionInput.checked = true
        this.selectedNetworkFeeByByte = 10
      }
    },

    // Клик по select в кастомной комиссии
    selectCustomFeeWithSelectClick() {
      const customSelect = document.getElementById("input-radio-fee-select-custom")
      customSelect.checked = true

      this.selectCustomFee()
    }
  },
  watch: {
    // При изменении комиссии передаём её родителю
    selectedNetworkFeeByByte: async function() {
      this.$emit("updateSelectedNetworkFeeByByte", this.selectedNetworkFeeByByte)
    }
  }
}
</script>

<style lang="sass">
  .ordinals-order-registration-select-network-fee
    display: flex
    flex-direction: column

    @media (min-width: $desktopScreenMinWidth)
      gap: 40px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 20px

    .network-fee-selection
      display: flex
      gap: 10px

      > input
        display: none

        &:checked
          + *
            border-color: $green

      .network-fee-select
        width: 100%
        background-color: $networkFeeBackground
        padding-top: 30px
        padding-bottom: 30px
        +border-radius(10px)
        border: 1px solid $gray
        display: flex
        flex-direction: column
        align-items: center
        gap: 24px
        +user-select(none)
        cursor: pointer

        .select-option
          display: flex
          gap: 20px
          color: $gray

          @media (min-width: $desktopScreenMinWidth)

          @media (max-width: $mobileScreenMaxWidth)
            flex-direction: column
            align-items: center

          .network-fee-custom-select
            background-color: $networkFeeBackground
            display: flex
            text-align: center
</style>