<template>
  <div class="ordinals-order-registration-upload-file">
    <div class="upload-drag-cont" id="upload-drag-cont">
      <div class="upload-before" v-if="userFileFormData === null">
        <input type="file" id="fileSelect" name="fileSelect[]" multiple="multiple" class="ordinals-input-file"/>

        <label id="fileDrag" for="fileSelect"></label>

        <div class="upload-image"></div>

        <h5 class="l">Drag and drop your image here, or click to select image</h5>
      </div>

      <div class="upload-after" v-else>
        <div class="upload-file-info">
          <h3 class="l">{{ userFileName !== null ? userFileName : "no data" }}</h3>
          <div class="upload-file-param">
            <h6 class="m" :class="currentFileSize !== userFileSize ? 'oldValue' : ''">
              {{ this.getCurrentFileSize(this.userFileSize) }}
            </h6>

            <h6 class="m" v-show="currentFileSize !== userFileSize">
              {{ this.getCurrentFileSize(this.currentFileSize) }}
            </h6>

            <h6 class="m green" v-show="currentFileSize !== userFileSize">{{
                `${Math.floor((userFileSize - currentFileSize) / (userFileSize / 100) * 10) / 10} % Saved!`
              }}</h6>
          </div>

          <div class="upload-file-param">
            <h6 class="m" :class="userFileResolution[0] !== currentFileResolution[0] || userFileResolution[1] !== currentFileResolution[1] ? 'oldValue' : ''">
              {{ `${userFileResolution[0]}x${userFileResolution[1]}` }}
            </h6>

            <h6 class="m" v-show="userFileResolution[0] !== currentFileResolution[0] || userFileResolution[1] !== currentFileResolution[1]">
              {{ `${currentFileResolution[0]}x${currentFileResolution[1]}` }}
            </h6>
          </div>
        </div>

        <div
            class="upload-system-messages"
            id="upload-system-messages"
            v-show="statusMaxSize ||
            statusStartConverting ||
            statusConverted ||
            statusConvertingError ||
            statusStartCompressing ||
            statusCompressed ||
            statusCompressingError ||
            statusStartOptimizing ||
            statusOptimized ||
            statusOptimizingError"
        >
          <div class="red" v-show="statusMaxSize">
            <h6 class="xs">The maximum file size is 2 MB, please try again</h6>
          </div>

          <div class="orange" v-show="statusStartConverting">
            <div class="loading-round"></div>
            <h6 class="xs">Converting image to WebP format</h6>
          </div>

          <div class="red" v-show="statusConvertingError">
            <h6 class="xs">Error converting image to WebP format. Please, select the bitmap format of image or try again</h6>
          </div>

          <div class="green" v-show="statusConverted">
            <h6 class="xs">Image is converted to WebP format</h6>
          </div>

          <div class="orange" v-show="statusStartCompressing">
            <div class="loading-round"></div>
            <h6 class="xs">Image compression</h6>
          </div>

          <div class="red" v-show="statusCompressingError">
            <h6 class="xs">Image compression error. Please, try again</h6>
          </div>

          <div class="green" v-show="statusCompressed">
            <h6 class="xs">Image is compressed</h6>
          </div>

          <div class="orange" v-show="statusStartOptimizing">
            <div class="loading-round"></div>
            <h6 class="xs">Optimizing image</h6>
          </div>

          <div class="red" v-show="statusOptimizingError">
            <h6 class="xs">Image optimization error. Please, try again</h6>
          </div>

          <div class="green" v-show="statusOptimized">
            <h6 class="xs">Image is optimized</h6>
          </div>
        </div>

        <img :src="currentFileSrc" alt="" class="testImage" id="testImage">
      </div>
    </div>

    <div class="upload-option">
      <input
          type="radio"
          id="upload-optimize"
          class="ordinals-input-checkbox"
          @change="this.optimizingValue = true"
      >

      <label for="upload-optimize" class="upload-checkbox"></label>

      <h6 class="m"><label for="upload-optimize" class="upload-checkbox-title">Optimize Images</label></h6>
    </div>
  </div>
</template>

<script>
import {getImageResolutionFromURL} from "@/components/mixins/getImageResolutionFromURL";

export default {
  name: "ordinals-order-registration-upload-file",
  data() {
    return {
      // Статусы
      statusMaxSize: false,
      statusStartConverting: false,
      statusConverted: false,
      statusConvertingError: false,
      statusStartCompressing: false,
      statusCompressed: false,
      statusCompressingError: false,
      statusStartOptimizing: false,
      statusOptimized: false,
      statusOptimizingError: false,

      // Файл пользователя
      userFileURL: null,
      userFileName: null,
      userFileSize: null,
      userFileResolution: [],
      userFileType: null,
      userFileFormData: null,

      // Сконвертированный файл в формат WebP
      convertedToWebpFileArrayBuffer: null,
      convertedToWebpFileSize: null,
      convertedToWebpFileFormData: null,

      // Сжатый файл
      compressedFileArrayBuffer: null,
      compressedFileSize: null,
      compressedFileFormData: null,

      // Оптимизированный файл
      optimizedFileArrayBuffer: null,
      optimizedFileSize: null,
      optimizedFileFormData: null,

      // Готовность к оптимизации
      readyToOptimizing: false,

      // Текущий размер файла
      currentFileSize: 0,
      currentFileResolution: [],
      currentFileSrc: null,

      preOptimizingFormData: null,
      finallyFormData: null,

      optimizingValue: null,

      readyToOrder: false,
    }
  },
  methods: {
    getCurrentFileSize(sizeByte) {
      let sizeName = "B"
      let size = sizeByte

      if (size > 1024) {
        size /= 1024
        sizeName = "KB"

        if (size > 1024) {
          size /= 1024
          sizeName = "MB"
        }
      }

      return (Math.ceil(size * 10) / 10).toString() + ` ${sizeName}`
    },

    InitFile() {
      const fileSelect = document.getElementById("fileSelect")
      const fileDrag = document.getElementById("fileDrag")

      /* выбор файла */
      fileSelect.addEventListener("change", this.FileSelectHandler, false);

      /* проверка поддержки XHR2 */
      let xhr = new XMLHttpRequest();
      if (xhr.upload){

        /* сброс файла */
        fileDrag.addEventListener("dragover", this.FileDragOver, false);
        fileDrag.addEventListener("dragleave", this.FileDragLeave, false);
        fileDrag.addEventListener("drop", this.FileSelectHandler, false);
      }
    },

    FileDragOver(e){
      e.stopPropagation();
      e.preventDefault();
      e.target.classList.add("active")
    },

    FileDragLeave(e){
      e.stopPropagation();
      e.preventDefault();
      e.target.classList.remove("active")
    },

    FileSelectHandler(e) {
      this.FileDragLeave(e)
      this.userFileURL = (e.target.files || e.dataTransfer.files)[0]
    },

    async optimizeFile() {
      // Отображение процесса конвертации
      this.statusStartOptimizing = true
      this.readyToOrder = false

      // Запрос на конвертацию изображения в формат WebP
      try {
        const url = "http://5.63.152.219:8000/api/nft/v1/images/convert"
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: 'arraybuffer'
        }

        await this.axios.post(
            url,
            this.preOptimizingFormData,
            config
        ).then((response) => {
          this.statusStartOptimizing = false
          this.statusOptimized = true

          this.optimizedFileSize = response.headers["content-length"]
          this.optimizedFileArrayBuffer = response.data

          this.currentFileSize = this.optimizedFileSize
          this.currentFileResolution = [response.headers["width"], response.headers["height"]]
        })
      }
      catch(e) {
        this.statusStartOptimizing = false
        this.statusOptimized = false
        this.statusOptimizingError = true

        console.log("Error", e)
      }
    }
  },
  mounted() {
    if (window.File && window.FileList && window.FileReader){
      this.InitFile();
    }
  },
  watch: {
    // Происходит, когда пользователь перетянул файл в input[type=file]
    userFileURL: async function() {
      // Инициализация параметров файла
      this.userFileName = this.userFileURL.name
      this.userFileSize = this.userFileURL.size
      this.userFileType = this.userFileURL.type

      this.userFileResolution = await getImageResolutionFromURL(this.userFileURL)

      this.currentFileSize = this.userFileSize
      this.currentFileResolution = this.userFileResolution
      this.currentFileSrc = window.URL.createObjectURL(this.userFileURL)

      // Перемещение файла в форму (для отправки в запросе)
      try {
        let formData = new FormData()
        formData.append('image', this.userFileURL)
        this.userFileFormData = formData
      }
      catch(e) {
        console.log("Error", e)
      }
    },

    // Происходит, когда файл вользователя перемещается в FormData
    userFileFormData: async function() {
      if (this.currentFileSize > 2 * 1024 * 1024) {
        this.statusMaxSize = true
      } else {
        // Если формат пользовательского файла - не WebP
        if (this.userFileType !== "image/webp") {
          // Отображение процесса конвертации
          this.statusStartConverting = true

          // Запрос на конвертацию изображения в формат WebP
          try {
            const url = "http://5.63.152.219:8000/api/nft/v1/images/convert"
            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              responseType: 'arraybuffer'
            }

            await this.axios.post(
                url,
                this.userFileFormData,
                config
            ).then((response) => {
              this.statusStartConverting = false
              this.statusConverted = true

              this.convertedToWebpFileSize = response.headers["content-length"]
              this.convertedToWebpFileArrayBuffer = response.data

              this.currentFileSize = this.convertedToWebpFileSize
              this.currentFileResolution = [response.headers["width"], response.headers["height"]]
            })
          }
          catch(e) {
            this.statusStartConverting = false
            this.statusConverted = false
            this.statusConvertingError = true

            console.log("Error", e)
          }
        } else {
          this.convertedToWebpFileFormData = this.userFileFormData
        }
      }
    },

    convertedToWebpFileArrayBuffer: async function() {
      // Перемещение файла в FormData
      try {
        let formData = new FormData()
        formData.append('image', new Blob([this.convertedToWebpFileArrayBuffer], {type: "image/webp"} ))
        this.convertedToWebpFileFormData = formData

        let binary = ''
        let bytes = new Uint8Array(this.convertedToWebpFileArrayBuffer)
        let len = bytes.byteLength
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode( bytes[ i ] )
        }
        this.currentFileSrc = "data:image/webp;base64," + window.btoa( binary )
      }
      catch(e) {
        console.log("Error", e)
      }
    },

    // Происходит после проверки формата пользовательского файла (и возможной последующей конвертации в WebP)
    convertedToWebpFileFormData: async function() {
      if (this.currentFileSize > 307200) {
        this.statusStartCompressing = true

        try {
          const url = "http://5.63.152.219:8000/api/nft/v1/images/convert/base"
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: 'arraybuffer'
          }

          await this.axios.post(
              url,
              this.convertedToWebpFileFormData,
              config
          ).then((response) => {
            this.statusStartCompressing = false
            this.statusCompressed = true

            this.compressedFileSize = response.headers["content-length"]
            this.compressedFileArrayBuffer = response.data

            this.currentFileSize = this.compressedFileSize
            this.currentFileResolution = [response.headers["width"], response.headers["height"]]
          })
        }
        catch(e) {
          this.statusStartCompressing = false
          this.statusCompressed = false
          this.statusCompressingError = true

          console.log("Error", e)
        }
      }
      else {
        this.preOptimizingFormData = this.convertedToWebpFileFormData
        this.readyToOptimizing = true
        this.readyToOrder = true
      }
    },

    compressedFileArrayBuffer: async function() {
      // Перемещение файла в FormData
      try {
        let formData = new FormData()
        formData.append('image', new Blob([this.compressedFileArrayBuffer], {type: "image/webp"} ))
        this.compressedFileFormData = formData

        let binary = ''
        let bytes = new Uint8Array(this.compressedFileArrayBuffer)
        let len = bytes.byteLength
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode( bytes[ i ] )
        }
        this.currentFileSrc = "data:image/webp;base64," + window.btoa( binary )
      }
      catch(e) {
        console.log("Error", e)
      }
    },

    compressedFileFormData: async function() {
      this.preOptimizingFormData = this.compressedFileFormData
      this.readyToOptimizing = true
      this.readyToOrder = true
    },

    optimizedFileArrayBuffer: async function() {
      // Перемещение файла в FormData
      try {
        let formData = new FormData()
        formData.append('image', new Blob([this.optimizedFileArrayBuffer], {type: "image/webp"} ))
        this.optimizedFileFormData = formData

        let binary = ''
        let bytes = new Uint8Array(this.optimizedFileArrayBuffer)
        let len = bytes.byteLength
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode( bytes[ i ] )
        }
        this.currentFileSrc = "data:image/webp;base64," + window.btoa( binary )
      }
      catch(e) {
        console.log("Error", e)
      }
    },

    optimizedFileFormData: async function() {
      this.finallyFormData = this.optimizedFileFormData
      this.readyToOrder = true
    },

    preOptimizingFormData: async function() {
      this.finallyFormData = this.preOptimizingFormData
    },

    finallyFormData: function() {
      this.$emit("setFinallyFormData", this.finallyFormData)
    },

    currentFileSize: async function() {
      this.$emit("updateCurrentFileSize", this.currentFileSize)
      if (this.currentFileSize > 300 * 1024) {
        this.readyToOrder = false
      }
    },

    optimizingValue: async function() {
      if (this.readyToOptimizing === true) {
        await this.optimizeFile()
      }
    },

    readyToOptimizing: async function() {
      if (this.optimizingValue === true) {
        await this.optimizeFile()
      }
    },

    readyToOrder: async function() {
      this.$emit("updateReadyImageToOrder", this.readyToOrder)
    }
  }
}
</script>

<style lang="sass">
  @keyframes loading-round-rotating
    0%
      transform: rotate(0)

    100%
      transform: rotate(360deg)

  .ordinals-order-registration-upload-file
    display: flex
    flex-direction: column
    align-items: center
    gap: 20px
    width: 100%

    .upload-drag-cont
      background-color: $statusBarBackground
      width: 100%
      +border-radius(14px)
      border: 1px dashed $gray
      overflow: hidden
      min-height: 216px

      .upload-before
        display: flex
        flex-direction: column
        gap: 30px
        align-items: center
        justify-content: center
        padding: 0 24px
        width: 100%
        height: 100%
        position: relative

        #fileDrag
          position: absolute
          width: 100%
          height: 100%
          z-index: 2
          top: 0
          left: 0

          &.active
            background-color: $dragOver
            z-index: 3

        .upload-image
          width: 60px
          height: 60px
          background-image: url("/src/assets/images/svg/uploadArrow.svg")

      .upload-after
        padding: 40px 24px
        width: 100%
        height: 100%
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 20px
        display: flex

        .preview
          width: 100px
          height: 100px
          background-color: pink

        .upload-file-info
          display: flex
          flex-direction: column
          align-items: center
          gap: 10px

          > *:first-child
            word-break: break-all

          .upload-file-param
            display: flex
            gap: 10px

        .upload-system-messages
          display: flex
          flex-direction: column
          align-items: center
          gap: 5px

          > div
            display: flex
            gap: 10px
            align-items: center

            .loading-round
              width: 14px
              height: 14px
              border: 1px solid $orange
              border-top: 1px solid transparent
              +border-radius(999px)
              transform: rotate(0)
              animation: 1s loading-round-rotating infinite linear

    .upload-option
      display: flex
      gap: 10px
      +user-select(none)

      input
        display: none

        &:checked
          ~ .upload-checkbox
            background-image: url("/src/assets/images/svg/checkboxActive.svg")

      .upload-checkbox
        background-color: white
        width: 20px
        height: 20px
        +border-radius(3px)
        +background-image-settings()
        cursor: pointer

      .upload-checkbox-title
        cursor: pointer
</style>