<template>
  <div class="ordinals-check-past-orders">
    <h3 class="l">Past Orders:</h3>

    <div class="past-orders-list">
      <div class="past-order-item" v-for="(order, index) in ordersList" :key="index">
        <h6 class="m">{{ order.id }}</h6>

        <div class="past-order-item-date">
          <h6 class="m">{{ getDayMonthYearFromDate(order.date) }}</h6>

          <h6 class="m">{{ getHoursMinutesFromDate(order.date) }}</h6>
        </div>
      </div>
    </div>

    <button class="ordinals-button-type1" @click="clearAndRefreshIndexedDB">
      Clear order history
    </button>
  </div>
</template>

<script>
import {getDayMonthYearFromDate} from "@/components/mixins/getDayMonthYearFromDate";
import {getHoursMinutesFromDate} from "@/components/mixins/getHoursMinutesFromDate";
import {clearIndexedDB} from "@/components/mixins/clearIndexedDB";

export default {
  name: "ordinals-check-past-orders",
  props: {
    ordersList: Array
  },
  data() {
    return {
      getDayMonthYearFromDate,
      getHoursMinutesFromDate
    }
  },
  methods: {
    clearAndRefreshIndexedDB() {
      clearIndexedDB("ordersList")

      this.$emit("updateOrdersList")
    }
  },
  mounted() {
    console.log(this.ordersList)
  }
}
</script>

<style lang="sass">
  .ordinals-check-past-orders
    display: flex
    flex-direction: column
    align-items: center

    @media (min-width: $desktopScreenMinWidth)
      gap: 30px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 20px

    .past-orders-list
      display: flex
      flex-direction: column
      width: 100%

      @media (min-width: $desktopScreenMinWidth)
        gap: 20px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 10px

      .past-order-item
        display: flex
        flex-direction: column
        align-items: center
        gap: 10px
        background-color: $statusBarBackground
        padding: 30px 15px
        word-break: break-all
        +border-radius(10px)

        .past-order-item-date
          display: flex
          gap: 10px
          color: $gray
</style>