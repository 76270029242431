<template>
  <router-link to="/" class="ordinals-header">
    <img class="header-logo" src="/favicon.ico" alt="">
    <h1 class="l">Ordinalsmint.tech</h1>
  </router-link>
</template>

<script>
export default {
  name: "ordinals-header"
}
</script>

<style lang="sass">
  .ordinals-header
    display: flex
    align-items: center
    +user-select(none)

    @media (min-width: $desktopScreenMinWidth)
      gap: 20px

    @media (max-width: $mobileScreenMaxWidth)
      gap: 10px
      padding: 8px 9px

    .header-logo
      width: 56px
      height: 56px

      @media (min-width: $desktopScreenMinWidth)
        gap: 20px

      @media (max-width: $mobileScreenMaxWidth)
        gap: 10px
        padding: 8px 9px
</style>