import {createRouter, createWebHistory} from "vue-router";
import ordinalsOrder from "@/components/ordinals-order";
import ordinalsOrderRegistration from "@/components/order/ordinals-order-registration";
import ordinalsOrderPayment from "@/components/order/ordinals-order-payment";
import ordinalsOrderComplete from "@/components/order/ordinals-order-complete";
import ordinalsFaq from "@/components/ordinals-faq";
import ordinalsCheck from "@/components/ordinals-check";

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'order',
            component: ordinalsOrder,
            children: [
                {
                    path: '',
                    name: 'registration',
                    component: ordinalsOrderRegistration
                },
                {
                    path: 'payment',
                    name: 'payment',
                    component: ordinalsOrderPayment
                },
                {
                    path: 'complete',
                    name: 'complete',
                    component: ordinalsOrderComplete
                }
            ]
        },

        {
            path: '/faq',
            name: "faq",
            component: ordinalsFaq
        },

        {
            path: '/check',
            name: "check",
            component: ordinalsCheck
        }
    ],
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }
    }
})